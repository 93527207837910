import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Retix C | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/oferta/retix-c/" />
  </>
);

const Main = styled.main`
  font-family: 'Lato', sans-serif;
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const Piercing = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_4)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Retix&nbsp;C</h1>

        <p>Jest to zabieg odmładzający, który powoduje spektakularne efekty. Usuwa martwy naskórek, pobudza skórę do regeneracji.</p>

        <h2>Wskazania</h2>

        <ul>
          <li>przebarwienia, zmarszczki</li>
          <li>blizny</li>
          <li>zmęczona skóra</li>
          <li>trądzik</li>
        </ul>

        <h2>Zalecenia po zabiegu</h2>

        <p>3-4 dni skóra może być ściągnięta. Złuszczanie następuje po 2-3 dniach od wykonania zabiegu. Trwa około 4 dni. W&nbsp;tym czasie używamy delikatnych kosmetyków. Przez minimum miesiąc po zabiegu stosujemy kremy z&nbsp;wysokim filtrem SPF.</p>

        <h2>Efekty zabiegu Retix&nbsp;C</h2>

        <p>Zabieg ten skuteczny jest w&nbsp;walce z&nbsp;przebarwieniami skóry, niweluje zmarszczki, odbudowuje włókna kolagenowe.</p>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_4: file(relativePath: {regex: "/hero\/4.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    }
  }
`;

export default Piercing;
